import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import badgeIcon from "../../images/badge.png"

import "./Clients.css";


function Clients(props) {
    const { data, clients } = props;
    return (
        <div className="pb-32 md:pb-64 m-auto px-4 md:px-0 container-cs" style={{paddingTop: "12rem"}}>
            <div>
                {/* <img className='m-auto' src={badgeIcon} width="155px" /> */}
                <h2 className='text-center'>{data.title}</h2>
                <p className='ss-sub py-4' style={{ textAlign: "center" }}>{data.subtitle}</p>
            </div>
            <div className='py-12'>
                <div className='flex flex-wrap justify-center'>
                    {(clients && clients.length > 0) && clients.map((client, i) => (
                        <div className='md:w-1/4 w-full pb-12 md:pb-4 px-4 pt-0 md:pt-8'>
                            <GatsbyImage
                                image={client.logo.localFile.childImageSharp.gatsbyImageData}
                                alt={client.logo.altText}
                                className="m-auto"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Clients;
