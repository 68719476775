import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

import "./PromoSection.css";


function PromoSection2(props) {
    const data = props.data;
    console.log(data)
    return (
        <div className="py-16 md:py-32 m-auto text-center px-4 md:px-0 container-cs">
            <div className='flex flex-wrap py-16 md:py-32'>
                <div className='w-full md:w-2/3 ss-container flex items-center justify-start px-4 md:px-8'>
                    <div>
                        <h2 className='w-full text-left' >
                            {data.title}
                        </h2>
                        <div className='ss-subtitle py-6'>
                            <span>{data.subtitle}</span>
                        </div>
                        <div className='ss-content' dangerouslySetInnerHTML={{ __html: data.content }} />
                        <div className='hidden md:flex'>
                            <a className='main-bttn w-full ' style={{ maxWidth: "500px" }} href={"https://ficon.gr/services/financial-reporting-program/"}>
                                <span>
                                    Περισσότερα
                                </span>
                            </a>
                        </div>


                    </div>
                </div>
                <div className='w-full md:w-1/3 px-0 md:px-2 py-8'>
                    <div>
                        <GatsbyImage
                            image={data.image.localFile.childImageSharp.gatsbyImageData}
                            alt={data.image.altText}
                            className="m-auto"
                        />
                    </div>
                    <div className='flex md:hidden pt-12'>
                        <a className='main-bttn w-full ' style={{ maxWidth: "500px" }} href={"https://ficon.gr/services/financial-reporting-program/"}>
                            <span>
                                Περισσότερα
                            </span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PromoSection2;
