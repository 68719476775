import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import { Link, graphql } from "gatsby"

import { Helmet } from "react-helmet"

import ReactPlayer from 'react-player'

import "./Introduction.css";

function Introduction({ data }) {
    console.log(data)
    return (

        <div className='relative'>
            <div className='block'>
                <div style={{ display: "grid" }}>
                    {/* You can use a GatsbyImage component if the image is dynamic */}
                    <div
                        className='hidden md:block'
                        style={{
                            gridArea: "1/1",
                            // You can set a maximum height for the image, if you wish.
                            maxHeight: "100vh",
                            minHeight: "70vh"
                        }}
                    >
                        <GatsbyImage
                            style={{
                                gridArea: "1/1",
                                // You can set a maximum height for the image, if you wish.
                                minHeight: "100vh"

                            }}
                            // You can optionally force an aspect ratio for the generated image
                            // aspectRatio={3 / 1}
                            // This is a presentational image, so the alt should be an empty string
                            alt={data.mainImage.altText}
                            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                            loading={"eager"}

                            image={data.mainImage.localFile.childImageSharp.gatsbyImageData}

                            formats={["auto", "webp", "avif"]}
                        />
                    </div>
                    <div
                        className='block md:hidden'
                        style={{
                            gridArea: "1/1",
                            // You can set a maximum height for the image, if you wish.
                            minHeight: "100vh"
                        }}
                    >
                        <GatsbyImage
                            style={{
                                gridArea: "1/1",
                                // You can set a maximum height for the image, if you wish.
                            }}
                            // You can optionally force an aspect ratio for the generated image
                            // aspectRatio={3 / 1}
                            // This is a presentational image, so the alt should be an empty string
                            alt={data.mainImage.altText}
                            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                            loading={"eager"}

                            image={data.mainMobileImage.localFile.childImageSharp.gatsbyImageData}

                            formats={["auto", "webp", "avif"]}

                            className='mob-intro-img'
                        />
                    </div>
                    <div
                        style={{
                            // By using the same grid area for both, they are stacked on top of each other
                            gridArea: "1/1",
                            position: "relative",
                            // This centers the other elements inside the hero component
                            placeItems: "center",
                            display: "grid",
                        }}
                    >
                        {/* Any content here will be centered in the component */}
                        <div className="block container intro-home-wrapper">
                            <div className="p-4 w-full sm:w-2/3 container py-4 ">
                                <div className="px-0 sm:px-12 text-white text-left">
                                    {/* <p className="home-main-p py-4 m-auto">{data.subtitle}</p> */}
                                    <h1 className="home-h1">{data.title}</h1>
                                </div>
                            </div>
                            <div className="p-4 w-full flex justify-start pb-12">
                                <div className='w-full lg:w-1/2 md:pl-0 new-alignment'>
                                    <div className='intro-sd' dangerouslySetInnerHTML={{ __html: data.shortDescription }} />
                                    <div className='mt-8'>
                                        <Link to={"/" + data.button.url + "/"} className="home-intro-bttn">{data.button.label}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;
