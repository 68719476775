import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from "../components/Home/Introduction"
import ServicesSection from "../components/Home/ServicesSection"
import PromoSection from "../components/Home/PromoSection"
import PromoSection2 from "../components/Home/PromoSection2"
import Programs from "../components/Home/Programs"
import Clients from "../components/Home/Clients"
import ContactSection from "../components/Home/ContactSection"

import transitionBottomImg from "../images/Wave_grey_bottom_left_shape_01.png"


function HomeTemplate(props) {
    let data = props.data;

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://ficon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://ficon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }

        console.log(payload)
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section id="firstSection" className="relative" style={{ backgroundColor: "#1b305b"}}>
                    <Introduction
                        data={data.wpPage.homePageAcf?.introSection}
                    />
                </section>
                <section className="relative" style={{backgroundColor: "white"}}>
                    {/* <div className="bt_bb_section_bottom_section_coverage_image_reverce" >
                        <img src={transitionBottomImg} />
                    </div> */}
                    <PromoSection2
                        data={data.wpPage.homePageAcf?.promoSection2}
                    />
                    <div className="bt_bb_section_bottom_section_coverage_image" style={{zIndex: "99"}} >
                        <img src={transitionBottomImg} />
                    </div>
                </section>
                <section className="relative" style={{ backgroundColor: "#f7f7f5" }}>
                    <div className="bt_bb_section_bottom_section_coverage_image_reverce" >
                        <img src={transitionBottomImg} />
                    </div>
                    <PromoSection
                        data={data.wpPage.homePageAcf?.promoSection}
                    />
                </section>
                <section className="relative">
                    <div className="bt_bb_section_bottom_section_coverage_image_reverce" >
                        <img src={transitionBottomImg} />
                    </div>
                    <ServicesSection
                        data={data.wpPage.homePageAcf?.servicesSection}
                        services={data.allWpService.edges}
                    />
                    <div className="bt_bb_section_bottom_section_coverage_image">
                        <img src={transitionBottomImg} />
                    </div>
                </section>



                <section className="relative" style={{ backgroundColor: "#f7f7f5" }}>
                    <div className="bt_bb_section_bottom_section_coverage_image">
                        <img src={transitionBottomImg} />
                    </div>

                    <Programs
                        data={data.wpPage.homePageAcf?.programs}
                        programs={data.allWpPost.nodes}
                    />
                    <div className="bt_bb_section_bottom_section_coverage_image" >
                        <img src={transitionBottomImg} />
                    </div>
                </section>


                <section className="relative">
                    <div className="bt_bb_section_bottom_section_coverage_image_reverce" >
                        <img src={transitionBottomImg} />
                    </div>
                    <Clients
                        data={data.wpPage.homePageAcf?.clientsGroup}
                        clients={data.wpPage.homePageAcf?.clients}
                    />
                    <div className="bt_bb_section_bottom_section_coverage_image" >
                        <img src={transitionBottomImg} />
                    </div>
                </section>
                <section className="relative" style={{ backgroundColor: "#f7f7f5" }}>
                    <ContactSection
                        data={data.wpTemplate.globalInformation?.contactForm}
                    />
                </section>

            </div>
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`query GET_PAGE($id: String, $templateId: String, $language: WpLanguageCodeEnum) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        homePageAcf {
            introSection{
                title
                subtitle
                shortDescription
                mainImage {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
                mainMobileImage{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
                button {
                    label
                    url
                }
            }

            servicesSection {
                title
                subtitle
                cta {
                    label
                    link
                }
            }
            promoSection {
                title
                subtitle
                content
                image {
                    altText
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
                cta {
                    label
                    link
                }

                plan{
                    title
                    description
                    icon{
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, width: 80, layout: FIXED)
                            }
                        }
                    }
                    price
                    button{
                        label
                        link
                    }
                    keypoints{
                        text
                    }
                }
            }
            promoSection2 {
                title
                subtitle
                content
                image {
                    altText
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }
            programs {
                title
                subtitle
                cta{
                    label
                    link
                }
            }

            clientsGroup {
                title
                subtitle
            }

            clients {
                name
                url
                logo{
                    altText
                    sourceUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }


        allWpService(
            filter: { databaseId: { in: [337, 244, 217, 548] } }
            limit: 4
        ) {
        edges{
            node{
                title
                excerpt
                uri
                featuredImage {
                    node{
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
                serviceAcf{
                    serviceIcon{
                        sourceUrl
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, width: 112, layout: FIXED)
                            }
                        }
                        
                    }
                }
            }
        }
    }

    allWpPost(
        limit: 3
        filter: {language: {code: {eq: $language}}}
      ) {
        nodes {
            uri
            slug
            title
            date
            excerpt

            featuredImage{
                node{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }

            tags{
                nodes{
                    name
                }
            }
            
        }
    }
}
`
