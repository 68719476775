import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

import "./PromoSection.css";


function PromoSection(props) {
    const data = props.data;
    console.log(data)
    return (
        <div className="py-16 md:py-32 m-auto text-center px-4 md:px-0 container-cs">
            <div className='flex flex-wrap'>
                <div className='w-full md:w-2/3 ss-container flex items-center justify-start px-4 md:px-8'>
                    <div>
                        <h2 className='w-full text-left' >
                            {data.title}
                        </h2>
                        <div className='ss-subtitle py-6'>
                            <span>{data.subtitle}</span>
                        </div>
                        <div className='ss-content' dangerouslySetInnerHTML={{ __html: data.content }} />
                        <div className='hidden md:flex'>
                            <a className='main-bttn w-full ' style={{ maxWidth: "500px" }} href={data.cta.link}>
                                <span>
                                    {data.cta.label}
                                </span>
                            </a>
                        </div>

                    </div>
                </div>
                <div className='w-full md:w-1/3 px-0 md:px-2 py-8'>
                    <div className='pricing-wrapper'>
                        {/* <GatsbyImage
                            image={data.plan.icon.localFile.childImageSharp.gatsbyImageData}
                            alt={data.plan.icon.altText}
                            className="m-auto"
                        /> */}
                        <div>
                            <h3 className='pricing-title'>{data.plan.title}</h3>
                            <p className='pricing-p'>{data.plan.description}</p>
                        </div>
                        <ul className='pricing-ul'>
                            {data.plan.keypoints && data.plan.keypoints.map((pk, j) => (
                                <li key={`keypoint-${j}`}>{pk.text}</li>
                            ))}
                        </ul>
                        <div>
                            <div className='pl-price' dangerouslySetInnerHTML={{ __html: data.plan.price }} />

                            {/* <div className='px-4 pt-4'>
                                <a href={data.plan.button.link} className='main-bttn'>
                                    {data.plan.button.label}
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div className='flex md:hidden pt-12'>
                        <a className='main-bttn w-full ' style={{ maxWidth: "500px" }} href={data.cta.link}>
                            <span>
                                {data.cta.label}
                            </span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PromoSection;
